import { Ed25519PublicKey, InputGenerateTransactionPayloadData, MoveFunctionId } from '@aptos-labs/ts-sdk'
import {
  Button,
  ButtonProps,
  Image,
  Link,
  Pagination,
  Skeleton,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { useAptosWallet } from '@razorlabs/wallet-kit'
import invariant from 'invariant'
import { useMemo, useState } from 'react'
import { isDesktop } from 'react-device-detect'
import { NumericFormat } from 'react-number-format'
import { toast } from 'react-toastify'
import useSWR, { useSWRConfig } from 'swr'
import { WalletIcon } from '../../components/Icons.tsx'
import { BodyB1, BodyB2, TitleT1, TitleT2, TitleT4 } from '../../components/Texts'
import { aptos } from '../../constants'
import { MOSB } from '../../constants/token.ts'
import useRefreshBalanceFn from '../../hooks/useRefreshBalanceFn.ts'
import { useAppSelector } from '../../redux/hooks'
import { divpowToFraction, mulpowToFraction } from '../../utils/number'
import { useMediaQuery } from 'usehooks-ts'

const CONTRACT_ADDR = import.meta.env.VITE_MOSB_VAULT_CONTRACT as string
const CURRENT_MOSB_DEPOSIT_FN: MoveFunctionId = `${CONTRACT_ADDR}::counter::get_total_deposit`
const DEPOSIT_FN: MoveFunctionId = `${CONTRACT_ADDR}::counter::deposit`
const TOTAL_ITEMS_FN: MoveFunctionId = `${CONTRACT_ADDR}::counter::get_total_items`
const GET_ITEMS_FN: MoveFunctionId = `${CONTRACT_ADDR}::counter::get_items`

const useMosbBalance = () => useAppSelector((state) => state.wallet.balance[MOSB.id] || '0')

const getMosbDepositedMutateKey = (address?: string) => `${address}-mosb-deposited`
const useMosbDeposited = () => {
  const { address } = useAptosWallet()

  const data = useSWR(getMosbDepositedMutateKey(address), async () => {
    if (!address) return undefined

    const [balance] = await aptos.viewJson({
      payload: {
        function: CURRENT_MOSB_DEPOSIT_FN,
        functionArguments: [address],
      },
    })
    return { amount: balance as string | undefined }
  })

  return data
}

const getLeaderboardDataMutateKey = () => 'spin-leaderboard'

function DepositedMosbBalance() {
  const { data: { amount = '0' } = {}, isLoading } = useMosbDeposited()

  return (
    <div className="flex w-full justify-between gap-4 rounded-lg border-1 border-[#C6A67F] bg-transparent px-4 py-2 sm:px-2">
      <BodyB1>You deposited</BodyB1>
      <TitleT1 className="flex items-center gap-2">
        {isLoading ? (
          <Skeleton className="h-full w-[80px] rounded" />
        ) : (
          <>{divpowToFraction(amount, MOSB.decimals).toSignificant(MOSB.decimals)} </>
        )}
        MOSB
        <Image src="/images/mosb_icon.svg" width="26px" className="rounded-full" />
      </TitleT1>
    </div>
  )
}

function LeaderboardTable() {
  const {
    data = [],
    isLoading,
    isValidating,
  } = useSWR(
    getLeaderboardDataMutateKey(),
    async () => {
      const [totalItems] = await aptos.viewJson({
        payload: {
          function: TOTAL_ITEMS_FN,
        },
      })

      if (!totalItems) return []

      const total = Number(totalItems)

      // fetch leaderboard in batch with `batchSize` and `chunkSize`
      const chunkSize = 500
      const batchSize = 10
      const allItems: { address: string; amount: string; rank: number }[] = []

      for (let batch = 0; batch * batchSize * chunkSize < total; batch++) {
        const promises = []

        for (let chunk = batch * batchSize; chunk < (batch + 1) * batchSize; chunk++) {
          const offset = chunk * chunkSize

          if (offset >= total) break

          promises.push(
            aptos.viewJson({
              payload: {
                function: GET_ITEMS_FN,
                functionArguments: [offset, chunkSize],
              },
            }),
          )
        }

        const responses = await Promise.all(promises)

        const items = responses.flatMap((entry) => {
          const [addrs, values] = entry as unknown as [string[], string[]]
          return addrs.map((addr, idx) => ({
            address: addr,
            amount: values[idx],
            rank: 0,
          }))
        })

        allItems.push(...items)
      }

      const sortedItems = allItems
        // we can assume that users don't have more than MAX_SAFE_INTEGER / MOSB.decimals > 90M MOSB
        .sort((a, b) => Number(b.amount) - Number(a.amount))
        .reduce(
          (acc, { address, amount }) => {
            const rank =
              acc.length > 0 && acc[acc.length - 1].amount === amount ? acc[acc.length - 1].rank : acc.length + 1
            acc.push({ address, amount, rank })
            return acc
          },
          [] as typeof allItems,
        )

      return sortedItems
    },
    {
      refreshInterval: 60_000,
      // the fetcher is bandwidth-consumed as it yields requests in batch,
      // so we should disable frequent revalidations by default
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )
  const [{ page, pageSize }, setPagination] = useState({ page: 1, pageSize: 10 })
  const { account } = useAptosWallet()

  const [allRows, currentRank] = useMemo(() => {
    // treat unranked as 0
    const currentRank = data.find(({ address }) => address === account?.address)?.rank || 0

    return [
      data.map(({ address, amount, rank }) => {
        return (
          <TableRow key={address} className="border-b border-[#FFC585]">
            <TableCell>{rank}</TableCell>
            <TableCell>{address.length >= 9 ? address.slice(0, 5) + '...' + address.slice(-4) : address}</TableCell>
            <TableCell>{divpowToFraction(amount, MOSB.decimals).toSignificant(MOSB.decimals)}</TableCell>
          </TableRow>
        )
      }),
      currentRank,
    ]
  }, [data, account])

  const visibleRows = useMemo(() => {
    // page is 1-based so we need to subtract 1 from it to calculate offset
    return allRows.slice((page - 1) * pageSize, page * pageSize)
  }, [allRows, page, pageSize])

  return (
    <div className="flex w-full max-w-2xl flex-col justify-center ">
      <div className="flex justify-center p-2">
        <Image src="/images/vault-leaderboard-text.svg" className="h-[52px] w-[317px]" />
      </div>
      <Table
        removeWrapper
        aria-label="leaderboard"
        className="rounded-lg border border-[#C6A67F] text-content2"
        classNames={{
          base: 'gap-2',
          th: 'bg-[#C6A67F99] text-content2 !rounded-none',
        }}
        topContent={
          <div className="mt-2 flex items-center justify-center gap-2 font-GreekFreak text-xl">
            Your rank
            <span className="rounded-lg bg-primary px-3 py-2 font-GreekFreak leading-5">
              {currentRank}/{data.length}
            </span>
          </div>
        }
        bottomContent={
          !isLoading && (
            <Pagination
              className="mb-0.5 flex items-center justify-center"
              classNames={{
                item: 'bg-transparent text-content2 hover:!bg-primary hover:!bg-opacity-50',
                cursor: 'text-content2',
              }}
              total={Math.ceil(data.length / pageSize)}
              page={page}
              isCompact
              onChange={(page) => setPagination({ page, pageSize })}
            />
          )
        }
      >
        <TableHeader>
          <TableColumn width={50} align="end">
            No.
          </TableColumn>
          <TableColumn align="end">Address</TableColumn>
          <TableColumn align="end">MOSB deposited</TableColumn>
        </TableHeader>
        <TableBody
          emptyContent="Leaderboard is empty"
          isLoading={isLoading || isValidating}
          loadingContent={<Spinner size="lg" />}
        >
          {visibleRows}
        </TableBody>
      </Table>
    </div>
  )
}

function DepositInput() {
  const { account, signAndSubmitTransaction } = useAptosWallet()

  const balance = useMosbBalance()
  const refreshBalance = useRefreshBalanceFn()

  const { mutate } = useSWRConfig()

  const [value, setValue] = useState<string>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const fvalue = mulpowToFraction(value?.replaceAll(',', '') || '0', MOSB.decimals)
  const fbalance = divpowToFraction(balance, MOSB.decimals)

  const { text: depositButtonText, ...depositButtonProps }: Partial<ButtonProps> & { text: string } = (() => {
    switch (true) {
      case (value || 0) == 0:
        return { isDisabled: true, text: 'Enter an amount' }
      case fvalue.greaterThan(fbalance):
        return { isDisabled: true, text: 'Insufficient Balance' }
      default:
        return { isDisabled: false, text: 'Deposit' }
    }
  })()

  async function onSubmit() {
    try {
      invariant(account, 'Not signed in')

      setIsSubmitting(true)

      const depositAmount = fvalue.multiply(Math.pow(10, MOSB.decimals)).toSignificant(MOSB.decimals)
      const payload: InputGenerateTransactionPayloadData = {
        function: DEPOSIT_FN,
        functionArguments: [depositAmount],
      }

      // 1. build transaction
      const transaction = await aptos.transaction.build.simple({
        sender: account.address,
        data: payload,
      })

      // 2. simulate
      const simulateResponses = await aptos.transaction.simulate.simple({
        signerPublicKey: new Ed25519PublicKey(account.publicKey),
        transaction,
      })

      invariant(simulateResponses.length === 1, 'simulateResponse length should be 1')
      const simulateResponse = simulateResponses[0]
      invariant(simulateResponse.success, simulateResponse.vm_status)

      // 3. submit
      const submitResponse = await signAndSubmitTransaction({ payload })

      // 4. Get submitted transaction hash.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      invariant(submitResponse.status === 'Approved', 'submitResponse.status REJECTED')

      // 5. Evaluate transaction status.
      const settleResult = await aptos.waitForTransaction({ transactionHash: submitResponse.args.hash })
      invariant(settleResult.success, settleResult.vm_status)

      // 6. Send notification.
      toast(
        settleResult.success ? (
          <div className="rounded bg-[rgba(24,207,106,0.2)] p-4">
            <TitleT2>Deposited successfully</TitleT2>
            {settleResult.hash && (
              <Link
                href={`https://explorer.movementnetwork.xyz/txn/${settleResult.hash}?network=testnet`}
                isExternal
                showAnchorIcon
                className="text-light2"
              >
                <TitleT4>View on explorer</TitleT4>
              </Link>
            )}
          </div>
        ) : (
          <div className="rounded bg-[rgba(244,70,70,0.2)] p-4">
            <TitleT2>Deposit failed</TitleT2>
            {settleResult.hash && (
              <Link
                href={`https://explorer.movementnetwork.xyz/txn/${settleResult.hash}?network=testnet`}
                isExternal
                showAnchorIcon
                className="text-light2"
              >
                <TitleT4>View on explorer</TitleT4>
              </Link>
            )}
          </div>
        ),
        {
          className: 'z-toast',
          bodyClassName: 'z-toast-body',
          progressClassName: settleResult.success ? 'z-toast-progress-success' : 'z-toast-progress-failed',
          autoClose: settleResult.success || isDesktop ? 4000 : false,
          pauseOnHover: isDesktop,
          position: 'top-right',
        },
      )

      // reset the input
      setValue('')
    } catch (err) {
      console.error(err)

      const jsonErr = JSON.stringify(err)

      if (jsonErr !== `{"code":"WALLET.SIGN_TX_ERROR"}`) {
        let errorDetails: string | undefined

        if (typeof err === 'string') {
          errorDetails = err
        } else {
          errorDetails = (err as any)?.message || undefined
        }

        toast(
          <div className="rounded bg-[rgba(244,70,70,0.2)] p-4">
            <TitleT2>Error depositing</TitleT2>
            {errorDetails && <BodyB2>{errorDetails}</BodyB2>}
          </div>,
          {
            className: 'z-toast',
            bodyClassName: 'z-toast-body',
            progressClassName: 'z-toast-progress-failed',
            autoClose: isDesktop ? 4000 : false,
            pauseOnHover: isDesktop,
            position: 'top-right',
          },
        )
      }
    } finally {
      setIsSubmitting(false)
      void refreshBalance()
      // refresh leaderboard
      void mutate(getLeaderboardDataMutateKey())
      // refresh current deposit
      void mutate(getMosbDepositedMutateKey(account?.address))
    }
  }

  return (
    <>
      <div className="flex w-full flex-col gap-2 rounded-lg border-1 border-[#C6A67F] px-4 py-2 sm:px-2">
        <div className="flex h-7 justify-between">
          <BodyB1>You&apos;re depositing</BodyB1>
          <div className="flex items-center gap-1 bg-transparent p-0 text-content2 disabled:opacity-100">
            <WalletIcon size={24} />
            <BodyB2>{divpowToFraction(balance, MOSB.decimals).toSignificant(MOSB.decimals)}</BodyB2>
            <Button
              size="sm"
              className="ml-1 h-full min-w-fit bg-[#C6A67F99] text-inherit"
              disableAnimation
              disableRipple
              onClick={() => setValue(divpowToFraction(balance, MOSB.decimals).toSignificant(MOSB.decimals))}
            >
              Max
            </Button>
          </div>
        </div>

        <div className="flex items-center gap-2 rounded-md bg-[#C6A67F] ">
          <NumericFormat
            decimalSeparator="."
            allowedDecimalSeparators={[',']}
            thousandSeparator
            inputMode="decimal"
            autoComplete="off"
            autoCorrect="off"
            type="text"
            placeholder="0.00"
            minLength={1}
            maxLength={30}
            spellCheck="false"
            className="w-full rounded-md bg-inherit p-2 text-[36px] font-semibold outline-none placeholder:text-content4"
            pattern="^[0-9]*[.,]?[0-9]*$"
            value={value}
            allowNegative={false}
            onChange={(e) => setValue(e.target.value)}
          />
          <Button
            className="flex h-[42px] w-fit min-w-fit items-center gap-2 bg-transparent p-2 transition"
            disableAnimation
            disableRipple
          >
            <TitleT1 className="whitespace-nowrap text-content2">MOSB</TitleT1>
            <Image src="/images/mosb_icon.svg" width="26px" className="rounded-full" />
          </Button>
        </div>
      </div>

      <Button
        color="primary"
        className="h-[52px] rounded-md px-8"
        disableRipple
        onPress={onSubmit}
        isLoading={isSubmitting}
        {...depositButtonProps}
      >
        <TitleT1 className="font-GreekFreak text-[20px] font-semibold text-dark0">{depositButtonText}</TitleT1>
      </Button>
    </>
  )
}

function DepositPanel() {
  return (
    <div className="min-w-[400px] max-w-xl">
      <TitleT1 className="my-5 text-center font-GreekFreak !text-3xl">
        Deposit <span className="rounded-md bg-primary p-1 px-2 font-GreekFreak">MOSB</span> to the Vault
      </TitleT1>
      <div className="flex flex-col gap-2">
        <DepositedMosbBalance />
        <DepositInput />
      </div>
    </div>
  )
}

function BackgroundContainer() {
  const isSmallHeight = useMediaQuery('(max-height: 900px)')
  return (
    <>
      {!isSmallHeight && (
        <Image src="/images/vault_obj_1.svg" className="fixed bottom-0 left-[25vw] h-[50vh] lg:hidden" />
      )}
      <Image src="/images/vault_obj_2.svg" className="fixed bottom-[50vh] left-0 h-[10vh] lg:hidden" />
      <Image
        src="/images/vault_obj_3.svg"
        className="fixed bottom-[30vh] right-[30vh] h-[10vh] 2xl:right-[10vh] xl:right-[5vh] lg:hidden"
      />
    </>
  )
}

export default function VaultPage() {
  return (
    <main className="isolate flex justify-center">
      <BackgroundContainer />
      <div className="fixed top-0 h-full w-screen bg-[url(/images/vault-bg.png)] bg-cover bg-center bg-no-repeat opacity-[30%]" />
      <div className="isolate m-8 flex gap-20 bg-[url(/images/vault-table-bg.png)] bg-cover bg-center bg-no-repeat p-8 text-content2 2xl:gap-12 xl:gap-11 lg:m-8 lg:flex-col lg:items-center lg:gap-10">
        <DepositPanel />
        <LeaderboardTable />
      </div>
    </main>
  )
}
